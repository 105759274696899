import { FormControl, FormGroup, FormGroupDirective, NgForm } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";

export class RepeatPasswordErrorStateMatcher implements ErrorStateMatcher {
  confirmPasswordControlName: string;
  passwordControlName: string;

  constructor(passwordControlName: string, confirmPasswordControlName: string) {
    this.passwordControlName = passwordControlName;
    this.confirmPasswordControlName = confirmPasswordControlName;
  }
  
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (control && control.parent.get(this.passwordControlName).value !== control.parent.get(this.confirmPasswordControlName).value)
  }
}

export function RepeatPasswordValidator(group: FormGroup) {
  const password = group.controls.password.value;
  const passwordConfirmation = group.controls.passwordConfirm.value;

  return password === passwordConfirmation
    ? null
    : { passwordsNotEqual: true };
}