import { CartItem, PaginatedProducts, Product } from '@/core/models';
import { ProductService } from '@/core/services';
import { CartService } from '@/core/services/cart.service';
import { SettingsService } from '@/core/services/settings.service';
import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Title } from '@angular/platform-browser';
import { fromEvent, merge, of as observableOf, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, map, catchError, startWith, takeUntil } from 'rxjs/operators';
import { CartConfirmationModalComponent } from '../cart-confirmation-modal/cart-confirmation-modal.component';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$: Subject<void>;
  displayedColumns: string[] = ['sapCode', 'image', 'productName', 'color', 'dimensions', 'staffSaleType', 'staffSalePrice', 'quantity', 'homeDelivery', 'actions'];
  dataSource: MatTableDataSource<Product>;
  resultsLength: number;
  itemsInCart: number;

  private monetaryLimit: number;
  private quantityLimit: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(
    private titleService: Title,
    private productService: ProductService, 
    private cartService: CartService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    @Inject('BASE_URL') private baseUrl: string) {
    this.dataSource = new MatTableDataSource([]);
    this.unsubscribe$ = new Subject<void>();
  }

  ngOnInit() : void {
    this.titleService.setTitle('Home | Products')
    this.cartService.numberOfItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(i => this.itemsInCart = i);

    this.settingsService.getSettings()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(s => {
        this.monetaryLimit = s.monetaryLimit;
        this.quantityLimit = s.quantityLimit;
      });
  }
  
  ngAfterViewInit() {    
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        switchMap(() => {
          this.paginator.pageIndex = 0;
          
          return this.fetchProducts();
        }),
        map((data: PaginatedProducts) => {
          data.products = data.products.filter(p => p.quantity > 0 || p.allowBackorders);
          this.resultsLength = data.total;

          return data;
        }),
        catchError((error) => {
          console.error(error);
          return observableOf([]);
        }),
        takeUntil(this.unsubscribe$)
      ).subscribe((data: PaginatedProducts) => this.dataSource = new MatTableDataSource(data.products));

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => this.fetchProducts()),
        map((data: PaginatedProducts) => {
          this.resultsLength = data.total;
          //data.products = data.products.filter(p => p.quantity > 0 || p.allowBackorders);

          return data;
        }),
        catchError((error) => {
          console.error(error);
          
          return observableOf([]);
        }),
        takeUntil(this.unsubscribe$)
      ).subscribe((data: PaginatedProducts) => this.dataSource = new MatTableDataSource(data.products));
  }

  ngOnDestroy() : void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  fetchProducts() {
    return this.productService.getProducts(
      this.paginator.pageIndex, 
      this.paginator.pageSize, 
      this.input.nativeElement.value.trim(), 
      this.sort.active,
      this.sort.direction ? this.sort.direction.toUpperCase() : 'ASC');
  }

  addToCart(product: Product) {
    const cartItem = {
      quantity: 1,
      product
    };

    this.cartService.addToCart(cartItem);
    this.dialog.open(CartConfirmationModalComponent);
  }

  alreadyInCart(product: Product) {
    const cartItem = {
      quantity: 1,
      product
    };

    return this.cartService.isItemAlreadyInCart(cartItem);
  }

  getImage(product: Product): string {
    return `${this.baseUrl}/${product.id}.jpg`;
  }

  showAddToCartButton(product: Product): boolean {
    return product.quantity > 0 || product.allowBackorders;
  }

  getTooltip(productCategory: string): string {
    return productCategory === 'monetary'
      ? `Total value of £${this.monetaryLimit} per calendar year`
      : `${this.quantityLimit} items per calendar year`;
  }
}
