import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order, PaginatedOrders } from '../models';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getOrders(pageIndex: number, pageSize: number, filter: string = '', orderBy: string = 'createdAt', direction: string = 'DESC'): Observable<PaginatedOrders> {
    return this.http.get<PaginatedOrders>(`${this.baseUrl}/api/orders?pageIndex=${pageIndex}&pageSize=${pageSize}&filter=${filter}&orderBy=${orderBy}&direction=${direction}`);
  }

  getOrdersByOwner(pageIndex: number, pageSize: number, filter: string = '', orderBy: string = 'createdAt', direction: string = 'DESC'): Observable<PaginatedOrders> {
    return this.http.get<PaginatedOrders>(`${this.baseUrl}/api/orders/my-orders?pageIndex=${pageIndex}&pageSize=${pageSize}&filter=${filter}&orderBy=${orderBy}&direction=${direction}`);
  }

  getOrdersForOwner(ownerId: string, pageIndex: number, pageSize: number, filter: string = '', orderBy: string = 'createdAt', direction: string = 'DESC') {
    return this.http.get<PaginatedOrders>(`${this.baseUrl}/api/orders/owner/${ownerId}?pageIndex=${pageIndex}&pageSize=${pageSize}&filter=${filter}&orderBy=${orderBy}&direction=${direction}`);
  }

  prepareOrder(order: Order | any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/api/orders/prepare-order`, order);
  }

  createOrder(orderId: string) {
    return this.http.post(`${this.baseUrl}/api/orders/${orderId}`, {});
  }

  getClientToken() {
    return this.http.post(`${this.baseUrl}/api/orders/client-token`, {});
  }

  rejectOrder(orderId: string) {
    return this.http.post(`${this.baseUrl}/api/orders/reject-order/${orderId}`, {});
  }

  getOrder(orderId: string) {
    return this.http.get<Order>(`${this.baseUrl}/api/orders/${orderId}`);
  }

  deleteOrder(orderId: string) {
    return this.http.delete(`${this.baseUrl}/api/orders/${orderId}`);
  }
}
