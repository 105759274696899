import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  headerTitle: string;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title) {
  }

  ngOnInit(): void {
      const appTitle = this.titleService.getTitle();

    if (this.activatedRoute.firstChild) {
      this.activatedRoute.firstChild.data.subscribe(data => {
        this.headerTitle = data.title;
        this.titleService.setTitle(data.title);
      });
    }

    this.router.events
      .pipe(
        map(() => {
          let child = this.activatedRoute.firstChild;

          while (child && child.firstChild) {
            child = child.firstChild;
          }

          if (child) {
            if (child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            }
          }

          return appTitle;
        }))
      .subscribe((title: string) => {
        this.headerTitle = title;
        this.titleService.setTitle(title);
      });
  }

}
