import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Settings } from '../models';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getSettings() {
    return this.http.get<Settings>(`${this.baseUrl}/api/settings`);
  }

  saveSettings(settings: Settings) {
    return this.http.post(`${this.baseUrl}/api/settings`, settings);
  }
}
