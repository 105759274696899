import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.addToken(request))
    .pipe(
      catchError(error => throwError(error))
    );
  }

  private addToken(request: HttpRequest<any>) {
    const expiration = new Date(JSON.parse(localStorage.getItem('expiration')) * 1e3);
    
    if (expiration) {
      if (+expiration - +new Date() > 0){
        return request.clone({
          setHeaders: { 'Authorization': `Bearer ${localStorage.getItem('accessToken')}` }
        });
      } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('expiration');
        localStorage.removeItem('role');
      }
    } 

    return request;
  }
}

