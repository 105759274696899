import { RepeatPasswordErrorStateMatcher, RepeatPasswordValidator } from '@/core/utilities';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@/core/services';

@Component({
  selector:    'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls:   ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  passwordResetForm: FormGroup;
  passwordMatcher: RepeatPasswordErrorStateMatcher;

  constructor(private activeRoute: ActivatedRoute,
              private authService: AuthService) {
  }

  get password() {
    return this.passwordResetForm.get('password');
  }

  get passwordConfirm() {
    return this.passwordResetForm.get('passwordConfirm');
  }

  ngOnInit(): void {
    this.passwordMatcher   = new RepeatPasswordErrorStateMatcher('password', 'passwordConfirm');
    this.passwordResetForm = new FormGroup({
      password:        new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
      passwordConfirm: new FormControl('', Validators.compose([Validators.required, Validators.minLength(6)])),
    }, {validators: RepeatPasswordValidator})
  }

  public submitForm() {
    const route = this.activeRoute.snapshot;

    const data = {
      token:    route.params.token,
      password: this.passwordResetForm.value.password
    }

    this.authService.resetPassword(data)
      .subscribe(
        res => {
          console.log(res);
        },
        err => console.log(err));
  }
}
