import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClientRoutingModule } from './client-routing.module';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '@/shared/shared.module';
import { CartComponent } from './cart/cart.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProductListComponent } from './product-list/product-list.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderListComponent } from './order-list/order-list.component';
import { CartConfirmationModalComponent } from './cart-confirmation-modal/cart-confirmation-modal.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';


@NgModule({
  declarations: [
    HomeComponent, 
    HeaderComponent, 
    CartComponent, 
    NavbarComponent, 
    ProductListComponent, 
    CheckoutComponent, 
    OrderListComponent, 
    CartConfirmationModalComponent, 
    OrderConfirmationComponent
  ],
  entryComponents: [
    CartConfirmationModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ClientRoutingModule
  ]
})
export class ClientModule { }
