
import { Role } from '@/core/models';
import { AuthGuard } from '@/shared/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { HomeComponent } from './home/home.component';
import { OrderConfirmationComponent } from './order-confirmation/order-confirmation.component';
import { OrderListComponent } from './order-list/order-list.component';
import { ProductListComponent } from './product-list/product-list.component';

const routes: Routes = [
  {
     path: '', redirectTo: 'home', pathMatch: 'full'
  },
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: 'home',
        component: ProductListComponent, 
        canActivate: [AuthGuard],
        data: {
          roles: [Role.Staff],
          title: 'Home | Products'
        }
      },
      {
        path: 'cart',
        component: CartComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.Staff],
          title: 'Home | Cart'
        }
      },
      {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.Staff],
          title: 'Home | Checkout'
        }
      },
      {
        path: 'my-orders',
        component: OrderListComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.Staff],
          title: 'Home | Orders'
        }
      },
      {
        path: 'order-confirmation/:id',
        component: OrderConfirmationComponent,
        canActivate: [AuthGuard],
        data: {
          roles: [Role.Staff],
          title: 'Thank you!'
        }
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientRoutingModule { }
