import { AuthService } from '@/core/services';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;

  constructor(
    private titleService: Title,
    private authService: AuthService, 
    private router: Router,
    private snackBar: MatSnackBar) { }

  get email() {
    return this.forgotPasswordForm.get('email');
  }

  ngOnInit(): void {
    this.titleService.setTitle('Staff Sales | Forgot password');
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    });
  }

  submit() {
    if (this.forgotPasswordForm.valid) {
      this.authService.forgotPassword(this.email.value)
        .subscribe(
          () => this.router.navigate(['/sign-in']),
          (error) => {            
            this.snackBar.open(error.error.message, 'x');
          });
    }
  }
}
