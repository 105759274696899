import { Order, PaginatedOrders } from '@/core/models';
import { OrderService } from '@/core/services/order.service';
import { OrderDialogComponent } from '@/shared/components/order-dialog/order-dialog.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { fromEvent, merge, of as observableOf } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements AfterViewInit {

  displayedColumns: string[] = ['id', 'orderId', 'transactionId', 'userDetails.firstName', 'userDetails.lastName', 'userDetails.email', 'amount', 'status', 'actions'];

  dataSource: MatTableDataSource<Order>;
  resultsLength: number;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('input') input: ElementRef;

  constructor(private dialog: MatDialog, 
    private orderService: OrderService) {
    this.dataSource = new MatTableDataSource([]);
  }

  ngAfterViewInit() {    
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        switchMap(() => {
          this.paginator.pageIndex = 0;
          
          return this.fetchOrders();
        }),
        map((data: PaginatedOrders) => {
          this.resultsLength = data.total;

          return data;
        }),
        catchError((error) => {
          return observableOf([]);
        })
      ).subscribe((data: PaginatedOrders) => this.dataSource = new MatTableDataSource(data.orders));

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => this.fetchOrders()),
        map((data: PaginatedOrders) => {
          this.resultsLength = data.total;

          return data;
        }),
        catchError((error) => {
          console.error(error);
          
          return observableOf([]);
        })
      ).subscribe((data: PaginatedOrders) => this.dataSource = new MatTableDataSource(data.orders));
  }

  fetchOrders() {
    return this.orderService.getOrdersByOwner(
      this.paginator.pageIndex, 
      this.paginator.pageSize, 
      this.input.nativeElement.value.trim(), 
      this.sort.active,
      this.sort.direction ? this.sort.direction.toUpperCase() : 'ASC');
  }

  previewOrder(order) {
    const dialogRef = this.dialog.open(OrderDialogComponent, {data: order});
  }
  
}
