import { CartItem } from '@/core/models';
import { CartService } from '@/core/services/cart.service';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void>;

  items: CartItem[];
  numberOfItems: number;
  totalAmount: number;
  productsWithoutHomeDelivery: boolean;
  enableCheckout: boolean;

  constructor(private cartService: CartService,
    @Inject('BASE_URL') private baseUrl: string) {
    this.items = [];
    this.unsubscribe$ = new Subject<void>();
   }

  ngOnInit(): void {
    this.cartService.cartItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(i => {
        this.items = i;
      });

    this.cartService.numberOfItems$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(n => {
        this.numberOfItems = n;
      });

    this.cartService.totalAmount$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(n => {
        this.totalAmount = n;
      });

    this.cartService.productWithNoDelivery$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(c => {
        this.productsWithoutHomeDelivery = c !== 0;
        
        this.enableCheckout = !this.productsWithoutHomeDelivery;
      });

  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  increaseQuantity(item: CartItem) {
    this.cartService.increaseQuantity(item);
  }

  decreaseQuantity(item: CartItem) {
    this.cartService.decreaseQuantity(item);
  }

  removeFromCart(item: CartItem) {
    this.cartService.removeFromCart(item);
  }

  changeCollect(value: string) {
    this.enableCheckout = true;
    this.cartService.changeCollectIn(value);
  }

  getImage(item: CartItem) {
    return `${this.baseUrl}/${item.product.id}.jpg`;
  }
}
