import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router) {
    
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //return true;
      let currentUser = localStorage.getItem('accessToken');       

      if (currentUser) {
          let role = localStorage.getItem('role');

          if (route.data.roles && !route.data.roles.includes(role)) {
              this.router.navigate(['/sign-in']);

              return false;
          }

          return true;
      }

      this.router.navigate(['/sign-in'], { queryParams: { returnUrl: state.url } });

      return false;
  }
}
