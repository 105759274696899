import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CartItem } from '@/core/models';

@Injectable({
  providedIn: 'root',

})
export class CartService {
  private cartItems: BehaviorSubject<CartItem[]>;
  private total: BehaviorSubject<number>;
  private totalAmount: BehaviorSubject<number>;
  private collectIn: BehaviorSubject<string>;
  private productWithNoDelivery: BehaviorSubject<number>;
  numberOfItems$: Observable<number>; 
  cartItems$: Observable<CartItem[]>; 
  totalAmount$: Observable<number>;
  productWithNoDelivery$: Observable<number>;

  constructor() { 
    const cartItemsValue = JSON.parse(localStorage.getItem('cartItems')) || [];

    this.cartItems = new BehaviorSubject<CartItem[]>(cartItemsValue);
    this.total = new BehaviorSubject<number>(cartItemsValue.length);    
    this.totalAmount = new BehaviorSubject<number>(0);
    this.collectIn = new BehaviorSubject<string>(localStorage.getItem('collectIn'));
    this.productWithNoDelivery = new BehaviorSubject<number>(this.productWithoutHomeDelivery);
    this.productWithNoDelivery$ = this.productWithNoDelivery.asObservable();
    this.numberOfItems$ = this.total.asObservable();
    this.cartItems$ = this.cartItems.asObservable();
    this.totalAmount$ = this.totalAmount.asObservable();
    this.recalculate();
  }

  dispose() {
    localStorage.removeItem('cartItems');
    localStorage.removeItem('collectIn')
    this.cartItems.next([]);
    this.total.next(0);
    this.totalAmount.next(0);
    this.collectIn.next('');
  }

  addToCart(item: CartItem) {
    const items = [...this.cartItems.value];
    const itemIndex = items.findIndex(i => i.product.id === item.product.id);

    if (itemIndex > -1) {
      const selectedItem = items[itemIndex];
      selectedItem.quantity += item.quantity;
      items[itemIndex] = selectedItem;
    } else {
      items.push(item);
    }

    localStorage.setItem('cartItems', JSON.stringify(items));
    this.total.next(items.length);
    this.cartItems.next(items);
    this.recalculate();
  }

  removeFromCart(item: CartItem) {
    const items = [...this.cartItems.value];
    const itemIndex = items.findIndex(i => i.product.id === item.product.id);

    if (itemIndex > -1) {      
      items.splice(itemIndex, 1);
      
      localStorage.setItem('cartItems', JSON.stringify(items));
      this.total.next(items.length);
      this.cartItems.next(items);
      this.recalculate();
    }
  }

  increaseQuantity(item: CartItem) {
    const items = [...this.cartItems.value];
    const itemIndex = items.findIndex(i => i.product.id === item.product.id);

    if (itemIndex > -1) {
      const selectedItem = items[itemIndex];
      selectedItem.quantity++;
      items[itemIndex] = selectedItem;
      
      localStorage.setItem('cartItems', JSON.stringify(items));
      this.total.next(items.length);
      this.cartItems.next(items);
      this.recalculate();
    }
  }

  decreaseQuantity(item: CartItem) { 
    const items = [...this.cartItems.value];
    const itemIndex = items.findIndex(i => i.product.id === item.product.id);

    if (itemIndex > -1) {
      const selectedItem = items[itemIndex];
      selectedItem.quantity--;

      if (selectedItem.quantity <= 0) {
        selectedItem.quantity = 0;
      }

      items[itemIndex] = selectedItem;

      localStorage.setItem('cartItems', JSON.stringify(items));
      this.total.next(items.length);
      this.cartItems.next(items);
      this.recalculate();
    }
  }

  recalculate() {
    const items = [...this.cartItems.value];
    const totalAmount = items.reduce((total, current) => total + (current.product.staffSalePrice * 100 * current.quantity / 100), 0);
    
    this.totalAmount.next(+totalAmount.toFixed(2));
  
    this.productWithNoDelivery.next(this.productWithoutHomeDelivery);
    if (this.productWithoutHomeDelivery === 0) {
      this.changeCollectIn('');
    }
  }

  isItemAlreadyInCart(item: CartItem): boolean {
    return [...this.cartItems.value].findIndex(i => i.product.id === item.product.id) > -1;
  }

  changeCollectIn(location: string) {
    localStorage.setItem('collectIn', location)
    this.collectIn.next(location);
  }

  get includeHomeDelivery(): boolean {
    return [...this.cartItems.value].filter(i => i.product.homeDelivery === true).length > 0;
  }

  get productWithoutHomeDelivery(): number {
    return [...this.cartItems.value].filter(i => i.product.homeDelivery === false).length;
  }

  get itemsInCart(): CartItem[] {
    return [...this.cartItems.value];
  }

  get collectInValue(): string {
    return this.collectIn.value;
  }
}
