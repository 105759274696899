import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs';

import { Token, SignIn, PasswordReset, User } from '@/core/models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  signIn(signIn: SignIn): Observable<Token> {
    return this.http.post<Token>(`${this.baseUrl}/api/auth/login`, signIn);
  }

  signOut() {
    return this.http.post(`${this.baseUrl}/api/auth/sign-out`, {});
  }

  forgotPassword(email: string) {
    return this.http.post(`${this.baseUrl}/api/auth/forgot-password`, { email });
  }

  passwordReset(passwordReset: PasswordReset) {
    return this.http.post(`${this.baseUrl}/api/auth/password-reset`, { passwordReset });
  }

  profile(): Observable<User> {
    return this.http.get<User>(`${this.baseUrl}/api/auth/profile`, {});
  }

  getIntentSecret(): Promise<any> {
    return this.http.post<any>(`${this.baseUrl}/api/orders/intent-secret`, {}).toPromise();
  }

  getRoleFromToken(token: string) {
    const payload = this.parseToken(token);

    return payload['role'];
  }

  getExpirationFromToken(token: string) {
    const payload = this.parseToken(token);
    return payload['exp'];
  }


  private parseToken(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64)
      .split('')
      .map(c => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
      .join(''));

    return JSON.parse(jsonPayload);
  }

  public resetPassword(data: { password: any; token: any }) {
    return this.http.post(`${this.baseUrl}/api/auth/password-reset`, data);
  }
}
