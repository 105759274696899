import { Role } from '@/core/models/role';
import { AuthGuard } from '@/shared/guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [
  { 
    path: 'users', 
    component: DashboardComponent, 
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin],
      title: 'Users'
    },
    loadChildren: () => import('@/admin/user/user.module').then(s => s.UserModule) 
  },
  { 
    path: 'products', 
    component: DashboardComponent, 
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin],
      title: 'Products'
    },
    loadChildren: () => import('@/admin/product/product.module').then(p => p.ProductModule) 
  },
  { 
    path: 'orders', 
    component: DashboardComponent, 
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin, Role.AdminOrder],
      title: 'Orders'
    },
    loadChildren: () => import('@/admin/order/order.module').then(p => p.OrderModule) 
  },
  { 
    path: 'reports', 
    component: DashboardComponent, 
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin],
      title: 'Reports'
    },
    loadChildren: () => import('@/admin/report/report.module').then(p => p.ReportModule) 
  },
  { 
    path: 'settings', 
    component: DashboardComponent, 
    canActivate: [AuthGuard],
    data: {
      roles: [Role.Admin],
      title: 'Settings'
    },
    loadChildren: () => import('@/admin/settings/settings.module').then(p => p.SettingsModule) 
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule { }
