import { Role } from '@/core/models';
import { DataService } from '@/shared/services';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isExpanded: boolean = true;
  title: string;
  role: string;
  
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) { }

  get isAdmin(): boolean {
    return localStorage.getItem('role') === Role.Admin;
  }

  ngOnInit(): void {
    const appTitle = this.titleService.getTitle();
    
    if (this.activatedRoute.firstChild) {
      this.activatedRoute.firstChild.data.subscribe(data => {
        this.title = data.title;
        this.titleService.setTitle(data.title);
      });
    }

    this.router.events
      .pipe(
        map(() => {
          let child = this.activatedRoute.firstChild;

          while (child && child.firstChild) {
            child = child.firstChild;
          }

          if (child) {
            if (child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            }
          }

          return appTitle;
        }))
      .subscribe((title: string) => {
        this.title = title;
        this.titleService.setTitle(title);
      });
  }

  signOut() {
    localStorage.clear();
    this.router.navigate(['']);
  }
}
