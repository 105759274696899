import { DialogData } from '@/shared/models/dialog-data';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html'
})
export class ModalComponent {
  showLoader: boolean;
  yesClicked$: BehaviorSubject<boolean>;
  
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: DialogData) {
    this.showLoader = false;
    this.yesClicked$ = new BehaviorSubject<boolean>(false);
  }

  onYesClick() {
    this.showLoader = true;
    this.yesClicked$.next(true);
  }
}
