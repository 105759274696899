import { Order } from '@/core/models';
import { AuthService } from '@/core/services';
import { CartService } from '@/core/services/cart.service';
import { OrderService } from '@/core/services/order.service';
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { from, of, Subject, zip } from 'rxjs';
import { first, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as braintree from 'braintree-web';
import { BraintreeGateway } from 'braintree';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
  totalAmount: number;
  unsubscribe$: Subject<boolean>;
  checkoutForm: FormGroup;
  buttonClicked: boolean;
  paymantFormIsValid: boolean;

  @Input() amount: number;
  @Input() description: string;
  @ViewChild('cardElement') cardElement: ElementRef;
  @ViewChild('input') input: ElementRef;
  includeHomeDelivery: boolean;
  hostedFields: braintree.HostedFields;
  threeDSecure: braintree.ThreeDSecure;

  constructor(
    private cartService: CartService,
    private authService: AuthService,
    private orderService: OrderService,
    private snackBar: MatSnackBar,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {}

  get orderData(): Order {
    const { firstName, lastName, email, postcode } = this.checkoutForm.value;
    let userDetails = {};
    userDetails = { firstName, lastName, email, postcode };

    if (this.cartService.includeHomeDelivery) {
      const { address, city, phone } = this.checkoutForm.value;
      userDetails = { ...userDetails, address, city, phone };
    }

    let orderData = {
      cartItems: this.cartService.itemsInCart,
      userDetails: userDetails,
    };

    if (this.cartService.productWithoutHomeDelivery) {
      return {
        ...orderData,
        collectIn: this.cartService.collectInValue,
      };
    }

    return orderData;
  }

  ngOnInit(): void {
    this.unsubscribe$ = new Subject<boolean>();
    this.buttonClicked = false;
    this.paymantFormIsValid = false;
    this.cartService.totalAmount$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((a) => (this.totalAmount = a));

    this.checkoutForm = new FormGroup({
      firstName: new FormControl('', Validators.required),
      lastName: new FormControl('', Validators.required),
      email: new FormControl(
        '',
        Validators.compose([Validators.required, Validators.email])
      ),
    });

    if (this.cartService.includeHomeDelivery) {
      this.includeHomeDelivery = true;
      this.checkoutForm.addControl(
        'address',
        new FormControl('', Validators.required)
      );
      this.checkoutForm.addControl(
        'city',
        new FormControl('', Validators.required)
      );
      this.checkoutForm.addControl(
        'postcode',
        new FormControl('', Validators.required)
      );
      this.checkoutForm.addControl(
        'phone',
        new FormControl('', Validators.required)
      );
    }

    this.orderService.getClientToken()
      .subscribe(async (clientPayload) => {
        const clientToken = (clientPayload as any)['clientToken']
        const braintreeClient = await braintree.client.create({
          authorization: clientToken as string,
        });
        this.hostedFields = await braintree.hostedFields.create({
          client: braintreeClient,
          fields: {
            number: {
              selector: '#card-number',
            },
            cvv: {
              selector: '#cvv',
            },
            expirationDate: {
              selector: '#expiration-date',
            },
            postalCode: {
              selector: '#postal-code',
            },
          },
        });
        this.threeDSecure = await braintree.threeDSecure.create({
          authorization: clientToken,
          version: 2,
        });

        this.hostedFields.on('focus', (event) => {
          const field = event.fields[event.emittedBy];
          const matFormFieldContainer =
            field.container.parentElement.parentElement.parentElement;

          matFormFieldContainer.classList.add('mat-form-field-should-float');
          matFormFieldContainer.classList.add('mat-focused');
        });

        this.hostedFields.on('blur', (event) => {
          const field = event.fields[event.emittedBy];
          const matErrorContainer =
            field.container.parentElement.parentElement.querySelector(
              'mat-error'
            ).parentElement;
          const matFormFieldContainer =
            field.container.parentElement.parentElement.parentElement;

          if (field.isEmpty) {
            matFormFieldContainer.classList.remove(
              'mat-form-field-should-float'
            );
            matErrorContainer.classList.remove('mat-error-hide');
            matErrorContainer.classList.add('mat-error-show');
          } else {
            matFormFieldContainer.classList.remove('mat-focused');
            matErrorContainer.classList.add('mat-error-hide');
            matErrorContainer.classList.remove('mat-error-show');
          }

          if (!field.isValid) {
            matFormFieldContainer.classList.add('mat-form-field-invalid');
          } else {
            matFormFieldContainer.classList.remove('mat-form-field-invalid');
          }

          this.isPaymantFormValid();
        });
      });
  }

  isPaymantFormValid() {
    const fields = this.hostedFields.getState().fields;

    this.paymantFormIsValid = Object.keys(fields)
      .map((f) => fields[f].isValid)
      .reduce((prev, next) => prev && next, true);

    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  hasError(key: string, value: string): boolean {
    return this.checkoutForm.get(key).hasError(value);
  }

  fillUserInputs(checked: boolean) {
    if (checked) {
      this.authService
        .profile()
        .pipe(first())
        .subscribe((p) => {
          const { firstName, lastName, email } = p;
          this.checkoutForm.patchValue({ firstName, lastName, email });
        });
    } else {
      this.checkoutForm.patchValue({
        firstName: '',
        lastName: '',
        email: '',
      });
    }
  }

  makePurchase() {
    if (this.checkoutForm.valid && this.paymantFormIsValid) {
      this.buttonClicked = true;
      let preparedOrderData;

      from(this.hostedFields.tokenize())
        .pipe(
          switchMap((payload) => {
            return this.threeDSecure.verifyCard({
              nonce: payload.nonce,
              bin: payload.details.bin,
              amount: this.totalAmount,
              onLookupComplete: function (data, next) {
                next();
              },
            } as any);
          }),
          switchMap((_3DSPayload) => {
            return this.orderService.prepareOrder({
              ...this.orderData,
              paymentMethodNonce: _3DSPayload.nonce,
            });
          }),
          switchMap((result) => {
            preparedOrderData = result;

            return this.orderService.createOrder(result.orderId);
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe(
          () => {
            this.cartService.dispose();
            this.router.navigate([
              '/order-confirmation',
              preparedOrderData.orderId,
            ]);
          },
          (response) => {
            console.error(response);
            let message = 'Something went wrong';

            if (preparedOrderData) {
              this.orderService.rejectOrder(preparedOrderData.orderId);
            }

            if (response.error) {
              message = response.error.message;
            }

            this.snackBar.open(message, 'X');

            this.buttonClicked = false;
          }
        );
    }
  }
}

