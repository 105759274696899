import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CartItem } from '@/core/models/cart-item';
import { DialogData } from '@/shared/models/dialog-data';

@Component({
  selector: 'app-order-dialog',
  templateUrl: './order-dialog.component.html',
  styleUrls: ['./order-dialog.component.scss']
})
export class OrderDialogComponent implements OnInit {
  displayedColumns: string[] = ['index', 'name', 'sapCode', 'dimensions', 'color', 'homeDelivery', 'quantity', 'allowBackorders', 'price', 'total'];
  Math = Math;
  order: any;
  dataSource: MatTableDataSource<CartItem>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.order = this.data;
    this.dataSource = this.order.cartItems;
  }
}
