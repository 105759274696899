import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { PaginatedProducts, Product } from '@/core/models';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getProducts(pageIndex: number, pageSize: number, filter: string = '', orderBy: string = '_id', direction: string = 'ASC'): Observable<PaginatedProducts> {
    return this.http.get<PaginatedProducts>(`${this.baseUrl}/api/products?pageIndex=${pageIndex}&pageSize=${pageSize}&filter=${filter}&orderBy=${orderBy}&direction=${direction}`);
  }

  getProduct(productId: string): Observable<Product> {
    return this.http.get<Product>(`${this.baseUrl}/api/products/${productId}`);
  }

  deleteProduct(productId: string) {
    return this.http.delete(`${this.baseUrl}/api/products/${productId}`);
  }

  updateProduct(productId: string, product: Product) {
    return this.http.put(`${this.baseUrl}/api/products/${productId}`, product);
  }
  
  createProduct(product: Product) {
    return this.http.post(`${this.baseUrl}/api/products`, product);
  }
}