import { CartItem } from '@/core/models';
import { OrderService } from '@/core/services/order.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent implements AfterViewInit {
  displayedColumns: string[] = [
    'index',
    'sapCode',
    'name',
    'dimensions',
    'color',
    'homeDelivery',
    'quantity',
    'allowBackorders',
    'price',
    'total'
  ];

  order: any;
  dataSource: MatTableDataSource<CartItem>;

  constructor(private route: ActivatedRoute,
    private orderService: OrderService) { }

  ngAfterViewInit(): void {
    this.orderService
      .getOrder(this.route.snapshot.params.id)
      .subscribe((order: any) => {
        this.order = order;
        this.dataSource = this.order.cartItems;
      });
  }

}
