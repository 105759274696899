import { Role } from '@/core/models';
import { AuthService } from '@/core/services';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  signInForm: FormGroup;

  constructor(
    private titleService: Title,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar) { }

  get employeeNumber() {
    return this.signInForm.get('employeeNumber');
  }

  get password() {
    return this.signInForm.get('password');
  }

  ngOnInit(): void {
    this.titleService.setTitle('Staff Sales | Sign in');
    this.signInForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      employeeNumber: new FormControl('', [Validators.required]),
    });
  }

  submit() {
    if (this.signInForm.valid) {
      this.authService.signIn(this.signInForm.value)
        .subscribe((res) => {
          const role = this.authService.getRoleFromToken(res.accessToken);
          localStorage.setItem('role', role);
          localStorage.setItem('accessToken', res.accessToken);
          localStorage.setItem('expiration', this.authService.getExpirationFromToken(res.accessToken));

          if (role === Role.Admin) {
            this.router.navigate(['/products']);
          } else if (role === Role.AdminOrder) {
            this.router.navigate(['/orders']);
          } else {
            this.router.navigate(['/home']);
          }
        }, (error) => {
          let errorMessage = error.error.message ?? 'Something went wrong.';

          this.snackBar.open(errorMessage, 'x');
          console.error(`Something went wrong. ${error.error}`);
        });
    }
  }
}
